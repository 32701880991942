<script setup>

import { useElementSize } from '@vueuse/core'

const cover = ref(null)
const { width } = useElementSize(cover)

const props = defineProps({
  attrs: {
    type: Object,
  },
	id: {
		type: Number
	}
})

</script>

<template>
	<NuxtLinkLocale
		ref="cover"
		class="cover block relative overflow-hidden aspect-[3/4] text-light"
		:to="{ name: 'artist-slug', params: { slug: id } }"
	>
		<div class="flex items-end fvs600 tracking-[-0.48px] h-full" :class="`p-[${Math.round(width/14)}px]`">
			<NuxtImg preload class="absolute top-0 left-0 min-w-full min-h-full max-h-full max-w-full object-cover" :src="attrs.image?.url || '/temp/img-artist-profile.png'" />
			<span class="relative z-10" :class="`text-[${Math.round(width/16)}px]`">{{ attrs.title }}</span>
		</div>
	</NuxtLinkLocale>
</template>

<style lang="scss" scoped>

	.cover {
		img {
			transition: 0.35s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}

		&::after {
			position: absolute;
			content: '';
			bottom: 0;
			left: 0;
			width: 100%;
			height: 25%;
			opacity: 0.5;
			background: linear-gradient(180deg, rgba(46, 46, 44, 0.00) 0%, #2E2E2C 100%);
		}
	}

</style>